import GreycliffRegular from "./fonts/greycliff-cf-regular.woff2";
import GreycliffMedium from "./fonts/greycliff-cf-medium.woff2";
import GreycliffBold from "./fonts/greycliff-cf-bold.woff2";
import GreycliffHeavy from "./fonts/greycliff-cf-heavy.woff2";

export default {
  GreycliffRegular,
  GreycliffMedium,
  GreycliffBold,
  GreycliffHeavy,
};
