import { useStaticQuery, graphql } from "gatsby";

const useSiteMetadata = () => {
  const {
    site: { siteMetadata },
    allWpMenuItem: { edges: navLinks },
  } = useStaticQuery(graphql`
    query SiteMetaData {
      site {
        siteMetadata {
          title
          description
          author
          copyright
          siteUrl
          appId
          keywords
          contactNumbers {
            detroit {
              phone
              url
            }
            houston {
              phone
              url
            }
            sanAntonio {
              phone
              url
            }
            roanoke_lynchburg {
              phone
              url
            }
            orlando {
              phone
              url
            }
            jacksonville {
              phone
              url
            }
          }
        }
      }
      allWpMenuItem(sort: { fields: order, order: ASC }) {
        edges {
          node {
            label
            url
          }
        }
      }
    }
  `);
  return { siteMetadata, navLinks };
};

export default useSiteMetadata;
