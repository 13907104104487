/** @jsx jsx */
import { Box, Button, Text, jsx, Container, Flex } from "theme-ui";
import PropTypes from "prop-types";
import { Link as ScrollLink } from "react-scroll";
import { Link as GatsbyLink } from "gatsby";
import { Inline, Stack } from "raam";
import useSiteMetadata from "../hooks/use-site-metadata";
import StyledLink from "./Link.styled";

const Footer = ({ pathname }) => {
  const {
    siteMetadata: { copyright },
    navLinks,
  } = useSiteMetadata();

  return (
    <Box as="footer" bg="blue.xxdark" color="white" px="3" py="7">
      <Container>
        <Flex sx={{ justifyContent: "center", textAlign: "center" }}>
          <Stack gap="5">
            <Inline gap="5" mx="auto">
              {navLinks.slice(1, navLinks.length - 1).map((link) => {
                const linkAs =
                  link.node.label === `Blog` || pathname !== `/`
                    ? GatsbyLink
                    : ScrollLink;

                const scrollTo =
                  link.node.label !== `Blog` && pathname === `/`
                    ? link.node.url.replace(/[/#]/g, "")
                    : null;
                const linkTo =
                  link.node.label === `Blog` ? link.node.url : link.node.url;

                return (
                  <StyledLink
                    key={link.node.label}
                    as={linkAs}
                    color="white"
                    fontWeight="medium"
                    hoverColor="blue.light"
                    scrollTo={scrollTo}
                    textDecoration="none"
                    to={!scrollTo ? linkTo : null}
                  >
                    {link.node.label}
                  </StyledLink>
                );
              })}
              {pathname === `/` ? (
                <ScrollLink
                  duration={500}
                  to={navLinks[navLinks.length - 1].node.url.replace(
                    /[/#]/g,
                    ""
                  )}
                  smooth
                  spy
                >
                  <Button as="span" variant="outline">
                    {navLinks[navLinks.length - 1].node.label}
                  </Button>
                </ScrollLink>
              ) : (
                <GatsbyLink to={navLinks[navLinks.length - 1].node.url}>
                  <Button as="span" variant="outline">
                    {navLinks[navLinks.length - 1].node.label}
                  </Button>
                </GatsbyLink>
              )}
            </Inline>
            <Text>{copyright}</Text>
          </Stack>
        </Flex>
      </Container>
    </Box>
  );
};

Footer.propTypes = {
  pathname: PropTypes.string,
};

Footer.defaultProps = {
  pathname: "/",
};

export default Footer;
