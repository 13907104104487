/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import useSiteMetadata from "../hooks/use-site-metadata";
import ogImage from "../../static/ogImage.jpg";
import twImage from "../../static/twitterImage.jpg";

const SEO = ({ description, lang, pathname, meta, title }) => {
  const {
    siteMetadata: {
      author,
      title: siteTitle,
      description: siteDescription,
      siteUrl,
      appId,
      keywords,
    },
  } = useSiteMetadata();

  const metaDescription = description || siteDescription;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title || siteTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: `${siteUrl}${ogImage}`,
        },
        {
          property: `og:image:alt`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `${siteUrl}${pathname}`,
        },
        {
          property: `fb:app_id`,
          content: appId,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:image`,
          content: `${siteUrl}${twImage}`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      title={title}
      titleTemplate={pathname === `/` ? siteTitle : `%s | ${siteTitle}`}
    />
  );
};

SEO.propTypes = {
  pathname: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

export default SEO;
