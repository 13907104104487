/** @jsx jsx */
import { useState } from "react";
import PropTypes from "prop-types";
import { graphql, Link as GatsbyLink, useStaticQuery } from "gatsby";
import { Box, Container, Flex, jsx, Button } from "theme-ui";
import { Inline, Stack } from "raam";
import Img from "gatsby-image";
import { Link as ScrollLink } from "react-scroll";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/pro-solid-svg-icons";
import useSiteMetadata from "../hooks/use-site-metadata";
import StyledLink from "./Link.styled";

const Header = ({ className, sx, bg, color, pathname }) => {
  const {
    logoWhite: {
      childImageSharp: { fluid: omneLogoWhite },
    },
  } = useStaticQuery(graphql`
    query NavQuery {
      logoWhite: file(relativePath: { eq: "omneLogoWhite.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const breakpoints = useBreakpoint();
  const { navLinks } = useSiteMetadata();

  const [menuOpenState, setMenuOpenState] = useState(false);

  const burgerHandler = () => {
    setMenuOpenState(!menuOpenState);
  };

  return (
    <Box as="header" bg={bg} className={className} px="3" py="4" sx={sx}>
      <Container>
        <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <GatsbyLink to="/">
            <Img
              alt="Omne Logo"
              fluid={omneLogoWhite}
              sx={{ width: "155px", height: "45px" }}
            />
          </GatsbyLink>
          <Box as="nav">
            <Inline gap="5" sx={{ display: ["none", null, "flex"] }}>
              {navLinks.slice(1, navLinks.length - 1).map((link) => {
                const linkAs =
                  link.node.label === `Blog` || pathname !== `/`
                    ? GatsbyLink
                    : ScrollLink;

                const scrollTo =
                  link.node.label !== `Blog` && pathname === `/`
                    ? link.node.url.replace(/[/#]/g, "")
                    : null;
                const linkTo =
                  link.node.label === `Blog` ? link.node.url : link.node.url;

                return (
                  <StyledLink
                    key={link.node.label}
                    as={linkAs}
                    color={color}
                    fontWeight="medium"
                    hoverColor="blue.light"
                    scrollTo={scrollTo}
                    textDecoration="none"
                    to={!scrollTo ? linkTo : null}
                  >
                    {link.node.label}
                  </StyledLink>
                );
              })}
              {pathname === `/` ? (
                <ScrollLink
                  duration={500}
                  to={navLinks[navLinks.length - 1].node.url.replace(
                    /[/#]/g,
                    ""
                  )}
                  smooth
                  spy
                >
                  <Button as="span" variant="outline">
                    {navLinks[navLinks.length - 1].node.label}
                  </Button>
                </ScrollLink>
              ) : (
                <GatsbyLink to={navLinks[navLinks.length - 1].node.url}>
                  <Button as="span" variant="outline">
                    {navLinks[navLinks.length - 1].node.label}
                  </Button>
                </GatsbyLink>
              )}
            </Inline>
            <FontAwesomeIcon
              icon={menuOpenState ? faTimes : faBars}
              sx={{
                color: "white",
                cursor: "pointer",
                display: ["block", null, "none"],
                fontSize: "5",
                maxHeight: "8",
              }}
              fixedWidth
              onClick={burgerHandler}
            />
            {menuOpenState && breakpoints.tablet && (
              <Box
                as="nav"
                sx={{
                  position: "absolute",
                  right: ["3", null, null, null, "0"],
                  top: "80px",
                  backgroundColor: "white",
                  padding: 4,
                  borderRadius: "lg",
                  boxShadow: "default",
                  textAlign: "right",
                  zIndex: "1",
                }}
              >
                <Stack gap="3">
                  {navLinks.slice(0, navLinks.length - 1).map((link) => {
                    const linkAs =
                      link.node.label === `Blog` || pathname !== `/`
                        ? GatsbyLink
                        : ScrollLink;

                    const scrollTo =
                      link.node.label !== `Blog` && pathname === `/`
                        ? link.node.url.replace(/[/#]/g, "")
                        : null;
                    const linkTo =
                      link.node.label === `Blog`
                        ? link.node.url
                        : link.node.url;

                    return (
                      <StyledLink
                        key={link.node.label}
                        as={linkAs}
                        color="blue.xdark"
                        fontWeight="medium"
                        scrollTo={scrollTo}
                        textDecoration="none"
                        to={!scrollTo ? linkTo : null}
                      >
                        {link.node.label}
                      </StyledLink>
                    );
                  })}
                  {pathname === `/` ? (
                    <ScrollLink
                      duration={500}
                      to={navLinks[navLinks.length - 1].node.url.replace(
                        /[/#]/g,
                        ""
                      )}
                      smooth
                      spy
                    >
                      <Button as="span" color="blue.xdark" variant="outline">
                        {navLinks[navLinks.length - 1].node.label}
                      </Button>
                    </ScrollLink>
                  ) : (
                    <GatsbyLink to={navLinks[navLinks.length - 1].node.url}>
                      <Button as="span" color="blue.xdark" variant="outline">
                        {navLinks[navLinks.length - 1].node.label}
                      </Button>
                    </GatsbyLink>
                  )}
                </Stack>
              </Box>
            )}
          </Box>
        </Flex>
      </Container>
    </Box>
  );
};

Header.propTypes = {
  bg: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  pathname: PropTypes.string,
  sx: PropTypes.shape({}),
};

Header.defaultProps = {
  bg: "transparent",
  className: "",
  color: "white",
  pathname: "/",
  sx: null,
};

export default Header;
