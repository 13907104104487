/** @jsx jsx */
import { Flex, jsx } from "theme-ui";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import SEO from "../components/seo";
import GlobalStyles from "../../static/GlobalStyles";
import Footer from "../components/Footer";

const MainLayout = ({ children, description, pathname, title }) => (
  <Flex sx={{ flexDirection: "column", minHeight: "screenHeight" }}>
    <Helmet>
      <link
        as="style"
        href="https://use.fontawesome.com/releases/v5.15.4/css/svg-with-js.css"
        rel="preload"
      />
      <link
        href="https://use.fontawesome.com/releases/v5.15.4/css/svg-with-js.css"
        rel="stylesheet"
      />
    </Helmet>
    <GlobalStyles />
    <SEO description={description} pathname={pathname} title={title} />
    <main sx={{ flex: "1 1 auto" }}>{children}</main>
    <Footer pathname={pathname} />
  </Flex>
);

MainLayout.propTypes = {
  children: PropTypes.node.isRequired,
  description: PropTypes.string,
  pathname: PropTypes.string,
  title: PropTypes.string,
};

MainLayout.defaultProps = {
  description: null,
  pathname: "/",
  title: "",
};

export default MainLayout;
